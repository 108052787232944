<template>
  <div class="container">
    <PrimePreloader v-if="loading"/>
    <div v-else class="my-4">
      <h5 class="text-uppercase text-center my-2">Индивидуальный учебный план</h5>


      <!-- info section -->
      <div class="card my-3" v-if="Object.keys(studentInfos).length">
        <div class="card-body">
          <div>
            <p>
              <b>ФИО:</b> {{ studentInfos?.last_name }} {{ studentInfos?.first_name }} {{ studentInfos?.middle_name }}
            </p>
            <p><b>Баркод:</b> {{ studentInfos?.barcode }}</p>
            <p>
              <b>ОП:</b> {{ studentInfos?.educationProgram?.education_speciality_code }}
              {{ studentInfos?.educationProgram?.education_speciality_name }}
            </p>
            <p><b>Уровень обучения:</b> {{ studentInfos?.studyLevel?.name }}</p>
            <p><b>Форма обучения:</b> {{ studentInfos?.studyForm?.name }}</p>
          </div>
        </div>
      </div>
      <!-- end info section -->

      <div class="card my-3" v-if="Object.keys(studentCreditsInfo).length">
        <div class="card-body">
          <div>
            <p><b>Кредитов пройдено:</b> {{ studentCreditsInfo.disbursed }}</p>
            <p><b>Требуется пройти:</b> {{ studentCreditsInfo.credit_is_left }}</p>
            <!-- <p><b>Общее количество вашего ОП:</b> {{ studentCreditsInfo.credit_is_left }}  </p>-->
          </div>
        </div>
      </div>

      <!-- minor section -->
      <div class="my-3">
        <div class="ms-0 row row-cols-2" v-if="!isDisciplinesChosen">
          <div class="col">
            <h4>Стандартная оброзовательная программа</h4>
            <div>
              <p>Стандартные дисциплины от кафедры - это курсы, которые входят в учебный план основной
                специальности. Они являются основой учебной программы и предназначены для обеспечения
                достаточного уровня знаний и навыков, необходимых для профессиональной деятельности в
                соответствующей области.</p>
            </div>
            <div v-for="(minor, index) in minorDisciplines" :key="index">
              <button type="button" class="btn btn-primary mb-2 me-2" v-if="index === 0"
                      @click="setData(minor.education_module_id)">{{ minor.name }}
              </button>
            </div>
          </div>
          <div class="col" v-if="minorDisciplines.length > 1">
            <h4>Minor Дисциплины</h4>
            <div>
              <p>Майнор дисциплины - представляют собой дополнительные курсы, которые студенты могут выбирать,
                чтобы расширить свой кругозор и получить дополнительные знания в другой области. Они не являются
                обязательными и могут быть выбраны на усмотрение студента. Майнор дисциплины могут быть выбраны
                из того же факультета или из других факультетов в университете. Они могут быть полезны для развития
                универсальных навыков, таких как критическое мышление, коммуникация и управление проектами, а также
                для приобретения дополнительных знаний в другой области, которые могут быть полезны в будущей
                карьере.</p>
            </div>
            <div class="row row-cols-auto ms-0">
              <div class="p-0" v-for="(minor, index) in minorDisciplines"
                   :key="index">
                <button type="button" class="btn btn-primary mb-2 me-2"
                        v-if="minor.education_module_id && minor.education_module_id !== 'fast'"
                        @click="setData(minor.education_module_id)">{{ minor.name }}
                </button>
              </div>
            </div>
          </div>
          <div class="col" v-if="minorDisciplines.length > 1">
            <h4>Ускоренная программа</h4>
            <div>
              <p>Уникальная возможность для студентов получить образование в ускоренный срок вместо стандартных четырех
                лет. Программа спроектирована с учетом интенсивности и эффективности обучения, позволяя студентам
                ускоренно достичь своих учебных целей. Дисциплины будут изучаться во время летнего семестра.</p>
            </div>
            <div class="row row-cols-auto ms-0">
              <div class="p-0" v-for="(minor, index) in minorDisciplines"
                   :key="index">
                <button type="button" class="btn btn-primary mb-2 me-2"
                        v-if="minor.education_module_id === 'fast'"
                        @click="setData(minor.education_module_id)">{{ minor.name }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--      <div class="my-4" style="padding-left: 12px" v-if="chosenMinorId || chosenMinorId === null || isVisibleSemesterNames">-->
      <!--        <div class="ms-0 d-flex flex-row row row-cols-auto">-->
      <!--          <button type="button" class="btn btn-primary mb-2 me-2 text-capitalize"-->
      <!--                  @click="setData(chosenMinorId)">Осень-Весна</button>-->
      <!--          <button type="button" class="btn btn-primary mb-2 me-2 text-capitalize"-->
      <!--                  @click="setData(null, true)">Лето</button>-->
      <!--        </div>-->
      <!--      </div>-->


      <!-- disciplines section -->
      <div class="my-4">
        <div v-if="disciplines">
          <template v-for="(semester, semesterKey) in disciplines" :key="semesterKey">
            <div v-if="semester?.w_kv.length || semester?.kv.length" :class="[disciplines ? 'margin-bottom' : 'mb-4']">
              <div class="row fw-bold mb-1 mx-1">
                <div class="col-md-12 text-center">{{ semesterKey }}</div>
              </div>
              <div class="row fw-bold mx-1">
                <div class="col-md-5">
                  <div class="row">
                    <div class="col-12 col-md-2">№</div>
                    <div class="col-12 col-md-10">Название дисциплины</div>
                  </div>
                </div>
                <div class="col-md-1">Кредит</div>
                <div class="col-md-6">Действия</div>
              </div>

              <div class="row border border-2 rounded-3 mt-2 mb-3 py-2 mx-1"
                   v-for="(basicDiscipline, basicDisciplineIndex) in semester.w_kv" :key="basicDisciplineIndex"
                   :class="[basicDisciplinesModel[basicDiscipline.formation_education_program_id]?.error ? 'border-red' : 'border-light-blue']">
                <div class="col-md-5">
                  <div class="row">
                    <div class="col-12 col-md-2">
                      <span class="px-2 fs-4 text-center bg-light-blue rounded-3">
                        {{ basicDisciplineIndex + 1 }}
                      </span>
                    </div>
                    <div class="col-12 col-md-10">
                      <h5>
                        <a class="text-blue cursor-pointer text-decoration-none"
                           @click="openDisciplineInfoModal(basicDiscipline.formation_education_program_id)">
                          {{ basicDiscipline.education_discipline_name }}
                        </a>
                      </h5>
                      <div v-if="basicDiscipline?.edc_id !== null">
                        <small class="text-muted bg-light rounded">Сохранено</small>
                      </div>
                      <div class="my-2">
                        <a class="text-blue cursor-pointer text-decoration-none"
                           @click="openDisciplineGoalsModal(basicDiscipline.formation_education_program_id)">
                          Цели изучения дисциплины
                        </a>
                      </div>
                      <div class="mt-2 mb-1">
                        <router-link
                            :to="{ path: '/complaint-form', query: { complaint_type: 2, complaint_theme: basicDiscipline.education_discipline_name }}"
                            target="_blank"
                            class="text-danger cursor-pointer text-decoration-none">
                          Подать жалобу
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-1 font-size-1-15-rem d-flex align-items-center">
                  {{ basicDiscipline.credit }}
                </div>
                <div class="col-md-6 d-flex align-items-center">
                  <div v-if="!basicDiscipline?.is_practice" class="col-md-12">
                    <div v-if="basicDiscipline.prerequisite_disciplines.length === 0"
                         class="d-flex col-md-12 flex-column justify-content-between align-items-center mb-2">
                      <div class="d-flex col-md-12 align-items-center justify-content-between">
                        <select class="form-control form-select font-size-1-15-rem" id="members" style="width: 90%"
                                :disabled="isDisciplinesChosen"
                                @change="changeBasicDisciplinesModel(basicDiscipline.formation_education_program_id,
                                $event.target.value, Number(basicDiscipline.credit), semester.semester_type_id)"
                                v-model="basicDiscipline.edc_pps_id">
                          <option :value="null" disabled selected hidden>Выберите преподавателя</option>
                          <option :value="0">Вакансия</option>
                          <option v-for="(teacher, index) in basicDiscipline.teachers" :value="teacher.user_id"
                                  :key="index">{{ teacher.lastname }} {{ teacher.firstname }} {{ teacher.middlename }}
                          </option>
                        </select>
                        <i v-if="Object.keys(chosenDisciplinesIds[semester.semester_type_id]).includes(
                            basicDiscipline.formation_education_program_id) && !isDisciplinesChosen"
                           class="fa fa-refresh me-2" style="font-size: 20px; cursor: pointer;"
                           @click="deleteCredits(basicDiscipline.formation_education_program_id,
                           Number(basicDiscipline.credit), semester.semester_type_id)"></i>
                      </div>
                      <div class="mt-2" v-if="basicDisciplinesModel[basicDiscipline.formation_education_program_id].pps_id
                                              && basicDiscipline.teachers.length > 0
                                              && !(basicDisciplinesModel[basicDiscipline.formation_education_program_id].pps_id == '0')">
                        <a class="text-blue cursor-pointer text-decoration-none"
                           :href="`https://pps.uib.kz/user?id=${basicDisciplinesModel[basicDiscipline.formation_education_program_id].pps_id}`"
                           target="_blank">Узнать больше о преподавателе</a>
                      </div>
                    </div>
                    <div v-else>
                      <p class="mb-1">Для того, что бы успешно освоить данный курс, нужно освоить данные дисциплины:</p>
                      <div class="d-flex" v-for="(data, index) in basicDiscipline.prerequisite_disciplines"
                           :key="index">
                        <div class="ps-3">
                          <span v-for="(prerequisite, score) in data.disciplines" :key="score">
                            <i v-if="score === 0">&#8226;&nbsp;</i>{{ prerequisite.name }}<span
                              v-if="score < data.disciplines.length - 1">&nbsp;{{ data.type_name }}&nbsp;</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="font-size-1-15-rem">
                    Предмет не требует выбора преподавателя
                  </div>
                </div>
              </div>

              <div class="border border-2 rounded-3 mt-2 mb-3 row py-2 mx-1"
                   v-for="(fGroup, fGroupIndex) in semester.kv" :key="fGroupIndex"
                   :style="{backgroundColor: colorForFGroup[fGroup[0].f_group_id]||'#fff'}"
                   :class="[electiveDisciplinesModel[fGroup[0].f_group_id]?.error ? 'border-red' : 'border-light-blue']">
                <template v-for="(eDiscipline, eDisciplineIndex) in fGroup" :key="eDisciplineIndex">
                  <div class="col-md-5">
                    <div class="row">
                      <div class="col-12 col-md-2">
                        <span v-if="eDisciplineIndex===0" class="px-2 fs-4 text-center bg-light-blue rounded-3">
                          {{ semester.w_kv.length + fGroupIndex + 1 }}
                        </span>
                      </div>
                      <div class="col-12 col-md-10">
                        <h5>
                          <input class="form-check-input me-1" type="radio"
                                 :disabled="isDisciplinesChosen || eDiscipline.prerequisite_disciplines.length > 0"
                                 :checked="electiveDisciplinesModel[eDiscipline.f_group_id]?.formation_education_program_id === eDiscipline.formation_education_program_id"
                                 :value="eDiscipline.formation_education_program_id"
                                 @change="changeElectiveDisciplinesModel(true, eDiscipline.f_group_id, $event.target.value, Number(eDiscipline.credit), semester.semester_type_id)">
                          <a class="text-blue cursor-pointer text-decoration-none"
                             @click="openDisciplineInfoModal(eDiscipline.formation_education_program_id)">
                            {{ eDiscipline.education_discipline_name }}
                          </a>
                        </h5>
                        <div v-if="eDiscipline?.edc_id">
                          <small class="text-muted">Сохранено</small>
                        </div>
                        <div class="my-2">
                          <a class="text-blue cursor-pointer text-decoration-none"
                             @click="openDisciplineGoalsModal(eDiscipline.formation_education_program_id)">
                            Цели изучения дисциплины
                          </a>
                        </div>
                        <div class="mt-2 mb-1">
                          <router-link
                              :to="{ path: '/complaint-form', query: { complaint_type: 2, complaint_theme: eDiscipline.education_discipline_name }}"
                              target="_blank" class="text-danger cursor-pointer text-decoration-none">
                            Подать жалобу
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1 font-size-1-15-rem d-flex align-items-center">
                    {{ eDiscipline.credit }}
                  </div>
                  <div v-if="eDiscipline.prerequisite_disciplines.length < 1"
                       class="col-md-6 d-flex flex-column align-items-center justify-content-center mb-2">
                    <div class="d-flex align-items-center justify-content-between col-md-12">
                      <select class="form-control form-select font-size-1-15-rem" style="width: 90%"
                              v-if="electiveDisciplinesModel[eDiscipline.f_group_id].formation_education_program_id
                                === eDiscipline.formation_education_program_id"
                              :disabled="electiveDisciplinesModel[eDiscipline.f_group_id].formation_education_program_id
                                !== eDiscipline.formation_education_program_id || isDisciplinesChosen"
                              @change="changeElectiveDisciplinesModel(false, eDiscipline.f_group_id,
                              eDiscipline.formation_education_program_id, Number(eDiscipline.credit), semester.semester_type_id,  $event.target.value)"
                              v-model="electiveDisciplinesModel[eDiscipline.f_group_id].pps_id">
                        <option :value="null" disabled selected hidden>Выберите преподавателя</option>
                        <option :value="0">Вакансия</option>
                        <option v-for="(teacher, index) in eDiscipline.teachers" :value="teacher.user_id" :key="index">
                          {{ teacher.lastname }} {{ teacher.firstname }} {{ teacher.middlename }}
                        </option>
                      </select>
                      <i v-if="electiveDisciplinesModel[eDiscipline.f_group_id].formation_education_program_id === eDiscipline.formation_education_program_id
                      && !isDisciplinesChosen && Object.keys(chosenDisciplinesIds[semester.semester_type_id]).includes(eDiscipline.f_group_id)"
                         class="fa fa-refresh me-2" style="font-size: 20px; cursor: pointer;"
                         @click="deleteCredits(eDiscipline.f_group_id, Number(eDiscipline.credit), semester.semester_type_id)"></i>
                    </div>
                    <div class="mt-2" v-if="electiveDisciplinesModel[eDiscipline.f_group_id].pps_id
                                            && electiveDisciplinesModel[eDiscipline.f_group_id].formation_education_program_id === eDiscipline.formation_education_program_id
                                            && eDiscipline.teachers.length > 0
                                            && !(electiveDisciplinesModel[eDiscipline.f_group_id].pps_id == '0')">
                      <a class="text-blue cursor-pointer text-decoration-none"
                         :href="`https://pps.uib.kz/users?id=${electiveDisciplinesModel[eDiscipline.f_group_id].pps_id}`"
                         target="_blank">Узнать больше о преподавателе</a>
                    </div>
                  </div>
                  <div v-else-if="!isDisciplinesChosen"
                       class="col-md-6 d-flex flex-column align-items-center justify-content-center">
                    <div>
                      <p class="mb-1">Для того, что бы успешно освоить данный курс, нужно освоить данные дисциплины:</p>
                      <div class="d-flex" v-for="(data, index) in eDiscipline.prerequisite_disciplines" :key="index">
                        <div class="ps-2">
                          <p v-for="(prerequisite, score) in data.disciplines" :key="score">
                            <i v-if="score === 0">&#8226;&nbsp;</i>{{ prerequisite.name }}<span
                              v-if="score < data.disciplines.length - 1">&nbsp;{{ data.type_name }}&nbsp;</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr v-if="eDisciplineIndex!==(fGroup.length-1)" class="hr-light-blue my-2">
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>


      <div class="fixed-bottom d-flex justify-content-center flex-column"
           style="background: #FFFFFF; border-top: 1px solid rgba(0,0,0,.125);">
        <div class="text-center">
          <p v-if="validateEducationDisciplineChosen && (basicFormations.length||electiveFormations.length)
           && !isDisciplinesChosen && disciplines"
             class="my-1">Кнопка <b>сохранения</b> появится после выбора всех дисциплин</p>
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <Button v-if="!validateEducationDisciplineChosen && !isDisciplinesChosen" label="Сохранить"
                  :loading="btnSaveStatus" class="m-1" @click="saveDisciplines(studentId)"/>
          <Button v-if="isDisciplinesChosen || !disciplines.length" label="Сброс дисциплин" class="p-button-danger m-1"
                  @click="openResetModal()"/>
        </div>
      </div>
      <!-- end disciplines section -->

      <!-- discipline info modal -->
      <Dialog :header="disciplineInfo?.name" v-model:visible="displayDisciplineInfo"
              :style="{width: '98%', maxWidth: '800px'}" :modal="true" :closable="false">
        <div v-if="Object.keys(disciplineInfo).length">
          <div>
            Язык дисциплины: {{ disciplineInfo?.native_name }}
          </div>
          <div class="mt-3">
            Код дисциплины: {{ disciplineInfo?.code }}
          </div>
          <div class="mt-4">
            Описание: {{ disciplineInfo?.description }}
          </div>
        </div>
        <div v-else>Информация отсутствует</div>
        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeDisciplineInfoModal"/>
        </template>
      </Dialog>
      <!-- end discipline info modal -->


      <!-- discipline goals modal -->
      <Dialog :header="disciplineInfo?.name" v-model:visible="displayDisciplineGoals"
              :style="{width: '98%', maxWidth: '800px'}" :modal="true" :closable="false">
        <div v-if="Object.keys(disciplineInfo).length">
          <div>
            <h5>Знания</h5>
            <div class="mt-1">
              {{ disciplineInfo?.knowledge }}
            </div>
          </div>
          <div class="mt-3">
            <h5>Навыки</h5>
            <div class="mt-1">
              {{ disciplineInfo?.skills }}
            </div>
          </div>
          <div class="mt-3">
            <h5>Умения</h5>
            <div class="mt-1">
              {{ disciplineInfo?.abilities }}
            </div>
          </div>
        </div>

        <div v-else>Информация отсутствует</div>
        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeDisciplineGoalsModal"/>
        </template>
      </Dialog>
      <!-- end discipline goals modal -->


      <!-- discipline goals modal -->
      <Dialog header="Подтверждение" v-model:visible="displaySuccessReset"
              :style="{width: '98%', maxWidth: '800px'}" :modal="true" :closable="false">
        <div>
          <div class="mt-1">
            Вы действительно хотите сбросить все выбранные дисциплины? Внимание, это действие
            нельзя отменить. После сброса данных все информация будет удалена
            без возможности восстановления. Если вы уверены, что хотите продолжить,
            нажмите кнопку "Сбросить выбор".
          </div>
        </div>
        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeResetModal"/>
          <Button label="Сбросить выбор" icon="pi pi-times" class="p-button-text p-button-danger"
                  @click="resetDisciplines(studentId)"/>
        </template>
      </Dialog>
      <!-- end discipline goals modal -->
    </div>
  </div>
  <Dialog v-model:visible="visible" modal header="Выберите дисциплины, иначе не сможете пользоваться front.uib.kz"
          :style="{ width: '50vw' }">
  </Dialog>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex"
import {decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";
import {getCookie} from "@/utils/helpers/cookies.helpers";

export default {
  name: "ChooseDisciplines",
  data() {
    return {
      barcode: this.$route.query.barcode || null,
      studentId: null,
      isAdviser: false,
      loading: true,
      basicDisciplinesModel: {},
      electiveDisciplinesModel: {},
      btnSaveStatus: false,
      basicFormations: [],
      electiveFormations: [],
      colorForFGroup: {},
      colors: ['#E3F2FD', '#E8F5E9', '#FFF3E0', '#E0F2F1', '#FFEBEE'],
      displayDisciplineInfo: false,
      displayDisciplineGoals: false,
      displaySuccessReset: false,
      disciplineInfo: {},
      disciplinesByFormation: {},
      studentInfos: {},
      isDisciplinesChosen: false,
      educationDisciplinesChosen: false,
      chosenDisciplinesIds: {1: {}, 2: {}, 3: {}},
      chosenMinorId: '',
      semesterTypes: [{'id': 1, 'name': 'Осень-Весна'}, {'id': 2, 'name': 'Лето'}],
      studentCreditsInfo: {},
      visible: null
    }
  },
  computed: {
    ...mapState('chooseDisciplines', ['disciplines', 'minorDisciplines', 'semesters']),
    validateEducationDisciplineChosen() {
      const basic = Object.values(this.basicDisciplinesModel).some(i => !i.pps_id || !i.formation_education_program_id)
      const elective = Object.values(this.electiveDisciplinesModel).some(i => !i.pps_id || !i.formation_education_program_id)
      return basic || elective
      // return false;
    },
  },
  methods: {
    ...mapActions('chooseDisciplines', ['GET_NEXT_DISCIPLINES', 'RESET_CHOSEN_DISCIPLINES', 'GET_SUMMER_DISCIPLINES', 'POST_CHOOSE_DISCIPLINES', 'PUT_CHOOSE_DISCIPLINES', 'GET_DISCIPLINE_INFO', 'GET_STUDENT_CREDITS_INFO', 'GET_STUDENT_INFO', 'GET_MINOR_DISCIPLINES', 'GET_SEMESTER_TYPES']),
    ...mapMutations('chooseDisciplines', ['SET_DISCIPLINES']),
    deleteCredits(id, program_credits, semester_type_id) {
      delete this.chosenDisciplinesIds[semester_type_id][id]
      if (id in this.electiveDisciplinesModel) {
        this.deleteEdcPpsId(id)
        this.electiveDisciplinesModel[id].formation_education_program_id = null
        this.electiveDisciplinesModel[id].pps_id = null
      } else if (id in this.basicDisciplinesModel) {
        this.deleteEdcPpsId(id, false)
        this.basicDisciplinesModel[id].formation_education_program_id = null
        this.basicDisciplinesModel[id].pps_id = null
      } else {
        this.$error({title: 'Ошибка', body: `Кредиты по предмету с идентификатором ${id} не найдены!`})
      }
    },
    deleteEdcPpsId(id, isKv = true) {
      for (let semester in this.disciplines) {
        if (isKv) {
          for (let item in this.disciplines[semester].kv) {
            for (let discipline of this.disciplines[semester].kv[item]) {
              if (discipline.f_group_id === id) {
                discipline.edc_pps_id = null
              }
            }
          }
        } else {
          for (let discipline of this.disciplines[semester].w_kv) {
            if (discipline.formation_education_program_id === id) {
              discipline.edc_pps_id = null
            }
          }
        }
      }
    },
    async openDisciplineInfoModal(formation_education_program_id) {
      this.disciplineInfo = await this.getDisciplineInfo(formation_education_program_id)
      this.displayDisciplineInfo = true
    },
    closeDisciplineInfoModal() {
      this.displayDisciplineInfo = false
    },
    async openDisciplineGoalsModal(formation_education_program_id) {
      this.disciplineInfo = await this.getDisciplineInfo(formation_education_program_id)
      this.displayDisciplineGoals = true
    },
    closeDisciplineGoalsModal() {
      this.displayDisciplineGoals = false
    },
    openResetModal() {
      this.displaySuccessReset = true
    },
    closeResetModal() {
      this.displaySuccessReset = false
    },
    async getDisciplineInfo(formation_education_program_id) {
      if (!this.disciplinesByFormation[formation_education_program_id]) {
        this.disciplinesByFormation[formation_education_program_id] = await this.GET_DISCIPLINE_INFO(formation_education_program_id)
      }
      return this.disciplinesByFormation[formation_education_program_id]
    },
    changeBasicDisciplinesModel(formation_education_program_id, teacher_id, program_credit, semester_type_id) {
      switch (semester_type_id) {
        case 1:
          if (!Object.keys(this.chosenDisciplinesIds[String(semester_type_id)]).includes(formation_education_program_id)) {
            this.chosenDisciplinesIds[String(semester_type_id)][formation_education_program_id] = {'semester_type_id': String(semester_type_id)}
          }
          break

        case 2:
          if (!Object.keys(this.chosenDisciplinesIds[String(semester_type_id)]).includes(formation_education_program_id)) {
            this.chosenDisciplinesIds[String(semester_type_id)][formation_education_program_id] = {'semester_type_id': String(semester_type_id)}
          }
          break

        case 3:
          if (!Object.keys(this.chosenDisciplinesIds[String(semester_type_id)]).includes(formation_education_program_id)) {
            this.chosenDisciplinesIds[String(semester_type_id)][formation_education_program_id] = {'semester_type_id': String(semester_type_id)}
          }
          break
      }
      this.basicDisciplinesModel[formation_education_program_id].formation_education_program_id = formation_education_program_id
      this.basicDisciplinesModel[formation_education_program_id].pps_id = teacher_id
    },

    changeElectiveDisciplinesModel(isElectiveInput, f_group_id, formation_education_program_id, program_credit, semester_type_id, teacher_id = null) {
      if (!isElectiveInput) {
        switch (semester_type_id) {
          case 1:
            if (!Object.keys(this.chosenDisciplinesIds[String(semester_type_id)]).includes(f_group_id)) {
              this.chosenDisciplinesIds[String(semester_type_id)][f_group_id] = {'semester_type_id': String(semester_type_id)}
            }
            break

          case 2:
            if (!Object.keys(this.chosenDisciplinesIds[String(semester_type_id)]).includes(f_group_id)) {
              this.chosenDisciplinesIds[String(semester_type_id)][f_group_id] = {'semester_type_id': String(semester_type_id)}
            }
            break

          case 3:
            if (!Object.keys(this.chosenDisciplinesIds[String(semester_type_id)]).includes(f_group_id)) {
              this.chosenDisciplinesIds[String(semester_type_id)][f_group_id] = {'semester_type_id': String(semester_type_id)}
            }
            break
        }
      } else {
        if (this.chosenDisciplinesIds[semester_type_id][f_group_id] !== undefined) {
          delete this.chosenDisciplinesIds[semester_type_id][f_group_id]
        }
      }
      this.electiveDisciplinesModel[f_group_id].formation_education_program_id = formation_education_program_id
      this.electiveDisciplinesModel[f_group_id].pps_id = teacher_id
    },

    async resetDisciplines(student_id) {
      let resReset = await this.RESET_CHOSEN_DISCIPLINES(student_id)
      if (resReset?.success) {
        await this.setData(null)
        await this.SET_DISCIPLINES('');
        this.$message({text: 'Выбор успешно сброшен'})
        this.chosenMinorId = ''

        for (let i in this.chosenDisciplinesIds) {
          this.chosenDisciplinesIds[i] = {}
        }
        this.displaySuccessReset = false
        // location.reload();
      }
    },

    async saveDisciplines(student_id) {
      this.btnSaveStatus = true

      // Все комmенты для обновления данных
      // const putData = [
      //   ...Object.values(this.basicDisciplinesModel).filter(i => !!i.id),
      //   ...Object.values(this.electiveDisciplinesModel).filter(i => !!i.id)
      // ]

      const postData = [
        ...Object.values(this.basicDisciplinesModel).filter(i => !i.id && i.formation_education_program_id !== null && i.pps_id !== null),
        ...Object.values(this.electiveDisciplinesModel).filter(i => !i.id && i.formation_education_program_id !== null && i.pps_id !== null)
      ]

      for (let discipline of postData) {
        if (discipline.pps_id === '0') {
          discipline.pps_id = null
        }
      }

      if (student_id && this.isAdviser) {
        // putData.forEach(i => {
        //   i.student_data_id = student_data_id
        // })

        postData.forEach(i => {
          i.student_id = student_id
        })
      }
      // let putErrors = []
      let postErrors = []

      // if (putData.length) {
      //   let resPut = await this.PUT_CHOOSE_DISCIPLINES(putData)
      //   if (resPut?.success) {
      //     putErrors = resPut?.data?.not_saved || []
      //   }
      // }

      if (postData.length) {
        let resPost = await this.POST_CHOOSE_DISCIPLINES(postData)
        if (resPost?.success) {
          postErrors = resPost?.data?.not_saved || []
        }
      }

      this.loading = true
      // await this.setData(putErrors, postErrors)
      await this.setData(null)
      await this.disciplinesCheck()
      this.loading = false

      // if (!putErrors.length && !postErrors.length) {
      if (!postErrors.length) {
        this.$message({text: 'Данные успешно сохранились'})
      } else {
        this.$error({title: 'Данные успешно сохранились, кроме тех где выделено красным. Выберите заново преподавателей где выделено красным'})
      }

      this.btnSaveStatus = false
    },

    goToTeacher(userId) {
      sessionStorage.setItem('USER', userId)
      let route = this.$router.resolve({path: '/teacher-info'});
      window.open(route.href, '_blank');
    },
    formulateDisciplineModel(d, semester_type_id, education_module_id, model, putErrors, postErrors, isBasic = true) {
      return {
        id: model?.id || (d.edc_id ? d.edc_id : null),
        pps_id: model?.pps_id || (d.edc_id ? d.edc_pps_id : null),
        formation_education_program_id: model?.formation_education_program_id || (d.edc_id ? d.edc_formation_education_program_id : (isBasic ? d.formation_education_program_id : null)),
        error: model?.error || (d.edc_id ? putErrors.some(i => i.formation_education_program_id == d.formation_education_program_id) : postErrors.some(i => i.formation_education_program_id == d.formation_education_program_id)),
        semester_type_id,
        education_module_id
      }
    },
    async setData(education_module_id = null, putErrors = [], postErrors = []) {
      this.loading = true

      this.chosenMinorId = education_module_id

      this.isDisciplinesChosen = false

      this.basicFormations = []
      this.electiveFormations = []

      this.basicDisciplinesModel = {}
      this.electiveDisciplinesModel = {}

      this.colorForFGroup = {}

      let barcode = this.isAdviser ? this.barcode : null


      await this.GET_NEXT_DISCIPLINES({'education_module_id': this.chosenMinorId, 'barcode': barcode})

      await this.disciplinesCheck()
      if (this.disciplines) {
        const basicFormations = []
        const electiveFormations = []

        const basicDisciplinesModel = {}
        const electiveDisciplinesModel = {}

        const colorsLength = this.colors.length

        for (let s in this.disciplines) {
          for (let d in this.disciplines[s].w_kv) {
            if (this.disciplines[s].w_kv[d].edc_formation_education_program_id !== null && this.disciplines[s].w_kv[d].edc_pps_id === null) {
              this.disciplines[s].w_kv[d].edc_pps_id = '0'
            }
          }
          for (let f in this.disciplines[s].kv) {
            for (let d in this.disciplines[s].kv[f]) {
              if (this.disciplines[s].kv[f][d].edc_formation_education_program_id !== null && this.disciplines[s].kv[f][d].edc_pps_id === null) {
                this.disciplines[s].kv[f][d].edc_pps_id = '0'
              }
            }
          }
          this.disciplines[s].w_kv.filter(d => !d.is_practice && d.prerequisite_disciplines.length < 1).forEach(d => {

            basicFormations.push(d)
            basicDisciplinesModel[d.formation_education_program_id] = this.formulateDisciplineModel(d, this.disciplines[s].semester_type_id, this.chosenMinorId, basicDisciplinesModel[d.formation_education_program_id], putErrors, postErrors)
          })

          this.disciplines[s].kv.forEach((f, fIndex) => {
            this.colorForFGroup[f[0].f_group_id] = this.colors[fIndex % colorsLength]

            f.filter(d => d.prerequisite_disciplines.length < 1).forEach(d => {
              electiveFormations.push(d)
              electiveDisciplinesModel[d.f_group_id] = this.formulateDisciplineModel(d, this.disciplines[s].semester_type_id, this.chosenMinorId, electiveDisciplinesModel[d.f_group_id], putErrors, postErrors, false)
            })
          })
        }

        if (postErrors.length) {
          for (let f_group_id in electiveDisciplinesModel) {
            if (!electiveDisciplinesModel[f_group_id].formation_education_program_id) {
              electiveDisciplinesModel[f_group_id].error = true
            }
          }
        }

        this.basicFormations = basicFormations
        this.electiveFormations = electiveFormations

        this.basicDisciplinesModel = basicDisciplinesModel
        this.electiveDisciplinesModel = electiveDisciplinesModel
      }
      this.loading = false
    },
    async disciplinesCheck() {
      for (let semester in this.disciplines) {
        for (let discipline of this.disciplines[semester]['w_kv']) {
          let object = discipline.edc_id !== null && discipline.edc_id !== undefined
          if (object) {
            this.isDisciplinesChosen = true
            break
          }
        }
        for (let score in this.disciplines[semester]['kv']) {
          for (let kvDiscipline of this.disciplines[semester]['kv'][score]) {
            let object = kvDiscipline.edc_id !== null && kvDiscipline.edc_id !== undefined
            if (object) {
              this.isDisciplinesChosen = true
              break
            }
          }
        }
      }
    }
  },
  async mounted() {
    let barcode = this.barcode

    if (barcode && decryptCheckRole('adviser')) {
      this.isAdviser = true
    } else {
      barcode = getCookie('USERNAME')
    }

    await this.setData(null)
    if (!this.isDisciplinesChosen) {
      await this.SET_DISCIPLINES('')
      await this.GET_MINOR_DISCIPLINES(barcode)
    }


    if (barcode) {
      this.studentInfos = await this.GET_STUDENT_INFO(barcode)
      this.studentId = this.studentInfos.id
      this.studentCreditsInfo = await this.GET_STUDENT_CREDITS_INFO(barcode)
    }

    if (this.$route.query.notification) {
      this.$error({title: this.$route.query.notification})
      this.visible = true
    }
    this.loading = false
  }
}
</script>


<style scoped>

.text-blue {
  color: #448AFF;

}

.border-light-blue {
  border-color: #dbe1ff !important;
}

.hr-light-blue {
  color: #647eff;
  height: 2px;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-light-blue {
  background-color: #dbe1ff;
}

.border-red {
  border-color: #E57373 !important;
}

.font-size-1-15-rem {
  font-size: 1.15rem;
}

.margin-bottom:last-child {
  margin-bottom: 190px;
}

@media screen and (max-width: 450px) {
  .font-size-1-15-rem {
    font-size: 1rem;
  }

}
</style>
